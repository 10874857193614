<template>
  <div>
    <img  v-if="showImg" style="width:100%;" src="../../assets/images/iswechat.jpeg"/>
    <div @click="topay" style="text-align:center;">
      <!-- <img src="../../../src/assets/images/vip1all.png"/> -->
      <div v-if="vipinfo.isAvailable" style="border-radius: 10px;background-color: black;width: 80%;margin: 0 1rem;">
        <p style="color:rgb(249, 217, 88);font-size: 1rem;">VIP</p>
        <p style="color:white;font-size: 0.3rem;margin-left: 4rem;">有效期：{{vipinfo.startDate | setDateMMdd }}至{{vipinfo.endDate | setDateMMdd }}</p>
      </div>
      <button class="button1" v-else>马上成为VIP | ￥{{vipinfo.price}}</button>
      <p class="annotation">{{vipinfo.personalProfile}}</p>
      <p class="annotation">说明:成为该大师的vip后，可免费享该大师的所有预测资料/文章(注: 需在有效期内)</p>
      
    </div>
    <button class="button2" @click="refresh">刷新</button>
<!--    <a :href="href_info"></a>-->
    <p class="annotation">注：如果已充值，页面有误请点击上面的刷新按钮</p>
   
  </div>
</template>

<script>

import Moment from 'moment';
import isWeixin from "@/utils/utils.js";

export default {
  data(){
    return{
      showImg: false,
      test:'test',
      userid:this.$route.query.userid,
      price:this.$route.query.price,
      access_token:this.$route.query.access_token || '',
      mb_kind:this.$route.query.mb_kind,
      related_id:this.$route.query.related_id,
      vipinfo:{},
      href_info:"http://m.haojingchou.cn/#/commonvip?userid="+this.userid+"&access_token="+this.access_token+"&price="+this.price+"&mb_kind="+this.mb_kind+"&related_id="+this.related_id,
    }
  },
  methods:{
    async topay(){
      if(this.vipinfo.isAvailable){
        // console.log('已是会员无需再充值')
      }else{
         // console.log('普通vip充值',this.userid,this.price,this.access_token,this.mb_kind,this.related_id)
        let args = {
              appId:"wxfeb0f4d0858263a3",
              artId: "101",
              bodyContent: "付费阅读",
              openid: "",
              payId: 3,
              buyType: 5,
              price: this.price,
              titles: "付费阅读",
              transactionType: "H5",
              // money: n,
              userId: this.userid,
              extendJson: "{userId:"+this.userid+",mbKind:"+this.mb_kind+",relatedId:"+this.related_id+"}",
            }
            let res
            if(this.access_token===''){
              res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, {}, true)

            }else{
              res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, { headers: { access_token: this.access_token } }, true)

            }

          if(res && res.statusCode === 200){

            this.$toast.clear()
            let newUrl = res.prepayUrl;
            window.location.href = newUrl;
          } else {
            console.log(res.responseDescription);
          }
      }
     
    },
    async getvipinfo(){
      const res = await this.$HTTP.get(this, this.$API['API_MEMBERANON_FANS'],{userid : this.userid , mb_kind : this.mb_kind , related_id : this.related_id},{}, true)
                                if(res.statusCode == 200){
                                   console.log('后台返回的vip信息',res)
                                   this.vipinfo=res.data
                                  }
    },
    refresh(){
      this.getvipinfo()
    }
      // location.reload()
    //   setTimeout(function () {
    //                    location.reload();
    //                }, 300);
    // }
  },
  filters: {
    setDateMMdd(vle) {
      if (vle) { return Moment(vle).format('M月DD') }
      return '';
    }
  },
  mounted(){
    let isWeiXin = isWeixin();
        if (isWeiXin) {
            this.showImg=true
        }
    this.getvipinfo()
  }
}
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}
.vip{
  margin: 0.2rem;
  text-align: center;
 
  img{
    width: 100%;
    height: 2rem;
  }
  button{
    top: -1.2rem;
    font-size: 0.25rem;
    border: none;
    color: white;
    background-color: red;
  }
}
.button1{
    font-size: 0.6rem;
    border-radius: 4px;
    padding: 0.1rem 1rem;
    margin: 1rem 1rem;
    border: none;
    color: white;
    background-color: red;
}
.button2{
    font-size: 0.5rem;
    border-radius: 4px;
    padding: 0.1rem 1rem;
    margin: 1rem 3.4rem;
    border: none;
    color: white;
    background-color: red;
}
.annotation{
  margin:0;
  padding: 0.2rem 0.4rem 0 0.4rem;
  font-size: 0.3rem;
  text-align: left;
  text-indent: 0.6rem;
  opacity: 0.5;
}

</style>